<template>
  <div>
    <c-tab
      type="tabcard"
      align="left"
      :inlineLabel="true"
      :tabItems="tabItems"
      :height.sync="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :key="tab.key"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import { uid } from 'quasar'
export default {
  name: 'hazop-plan',
  props: {
    param: {
      type: Object,
      default: () => ({
        ramStepCd: '',
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      splitter: 5,
      tab: 'planInfo',
      editable: true,
      detailUrl: '',
      completeUrl: '',
      deleteUrl: '',
      isComplete: false,
    };
  },
  computed: {
    tabHeight() {
      let numHeight = this.height ? Number(this.$_.replace(this.height, 'px', '')) : 600;
      return numHeight + 'px';
    },
    tabItems() {
      return [
        { key: uid(), name: 'planInfo', icon: 'edit', label: '작업내용', component: () => import(`${'./wo002Detail02Tab01.vue'}`) },
        { key: uid(), name: 'team', icon: 'task_alt', label: '안전작업 허가서', component: () => import(`${'./wo002Detail02Tab02.vue'}`) },
        { key: uid(), name: 'team2', icon: 'photo', label: '작업결과 사진', component: () => import(`${'./wo002Detail02Tab03.vue'}`) },
      ]
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
    },
  }
};
</script>
